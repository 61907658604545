import { useEffect } from "react";

import useSWR from "swr";
import useSWRInfinite from "swr/infinite";

import { isStateCode } from "@components/Beacons/helpers";
import client, { buildFilters, jsonApiPaginate } from "@services/api";
import { buildFromPath } from "@utility/utilityFunctions";

import useSetParams from "./useSetParams";

export const useBeaconParams = () =>
  useSetParams("/beacons/:programId/:view/:state/:variantSku");
// useSWR de-dups the request so this hook can be called at different places
// on the same page without sending 2 network requests

export const useBeaconProgram = (
  { programId, state, variantSku },
  groupBy = ""
) => {
  const fetcherParams = [
    `beacons/${programId}/${groupBy}`,
    {
      params: {
        filter: buildFilters({
          states: isStateCode(state) ? state : null,
          variantSku,
        }),
      },
    },
  ];
  const { data, error, setSize, ...others } = useSWRInfinite(
    jsonApiPaginate(programId && fetcherParams),
    client.get,
    { revalidateAll: true }
  );
  const flattenedData = data?.flatMap((res) => res.data);

  useEffect(() => {
    // Load next page once previous page
    if (data?.length) setSize((size) => size + 1);
  }, [data?.length, setSize]);

  return {
    [groupBy || "beacons"]: flattenedData || [],
    isLoading: !data && !error,
    error,
    ...others,
  };
};

export const useProgramsWithBeacons = () => {
  const { data, error, ...others } = useSWR(
    ["programs", buildFromPath("params.filter.has-beacons", true)],
    client.get
  );

  return {
    programs: data?.data || [],
    isLoading: !data && !error,
    error,
    ...others,
  };
};
