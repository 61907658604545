import _ from "lodash";

import { User } from "@models/User";
import { UserRole } from "@models/UserRole";

type Entity = {
  id: string;
  name: string;
};

export type UserFormValues = {
  id: string;
  canUseStripe: boolean;
  email: string;
  vernonBillingId: string | null;
  name: string;
  receivesThresholdEmails: boolean;
  role: UserRole;
  status: "active" | "inactive";
  groups: Entity[];
  supplierId: string | null;
  territories: Entity[];
  channels: Entity[];
  accountManagerOrganizationIds: string[];
  purchasingAgentOrganizationIds: string[];
  /** Used to track which territories were selected before the user was saved */
  savedTerritoryIds?: string[];
};

const defaultValues: UserFormValues = {
  id: "",
  canUseStripe: false,
  email: "",
  vernonBillingId: null,
  name: "",
  receivesThresholdEmails: false,
  role: "read-only",
  status: "active",
  groups: [],
  supplierId: null,
  territories: [],
  channels: [],
  accountManagerOrganizationIds: [],
  purchasingAgentOrganizationIds: [],
  savedTerritoryIds: [],
};

const mapEntities = (entities: any[]): Entity[] =>
  _.map(entities, (ent) => ({ id: ent.id, name: ent.name }));

const formValuesFromUser = (user: User | undefined): UserFormValues => {
  if (!user) return defaultValues;
  return {
    ..._.pick(user, [
      "id",
      "name",
      "email",
      "vernonBillingId",
      "receivesThresholdEmails",
      "role",
      "status",
      "canUseStripe",
    ]),
    accountManagerOrganizationIds:
      user.accountManagerOrganizationIds.map(String),
    purchasingAgentOrganizationIds:
      user.purchasingAgentOrganizationIds.map(String),
    supplierId: user.supplier?.id ?? null,
    groups: mapEntities(user.groups),
    territories: mapEntities(user.territories),
    channels: mapEntities(user.channelUsers.map((cu) => cu.channel)),
  };
};

export default formValuesFromUser;
