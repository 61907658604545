/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link } from "react-router-dom";

import { Alert, CircularProgress } from "@mui/material";

import { format } from "date-fns/esm";

import { Contained, OpaqueCard } from "@components/StyledComponents";
import cld from "@services/cloudinary";
import { formats } from "@utility/dateTime";
import { utcDate } from "@utility/utilityFunctions";

import { useProgramsWithBeacons } from "../hooks/beaconHooks";
import DocTitle from "@utility/DocTitle";

const Beacons = () => {
  const { programs, isLoading, error } = useProgramsWithBeacons();

  return (
    <>
      <DocTitle title={"Beacons"}/>
      <Contained tw="space-y-4">
        <h2 tw="text-xl font-bold mt-2">Active Programs</h2>
        <div tw="grid grid-cols-1 md:grid-cols-2 gap-3">
          {isLoading && <CircularProgress />}
          {error && <Alert severity="error">Something went wrong</Alert>}
          {programs.map((program) => (
            <Link key={program.id} to={`/beacons/${program.id}`}>
              <OpaqueCard tw="hover:scale-[1.02] cursor-pointer transition duration-300 flex gap-4">
                <img
                  tw="w-24 aspect-square rounded-md object-cover flex-none"
                  src={cld.url(program.cloudinaryId)}
                  alt={program.name}
                />
                <div tw="flex flex-col">
                  <h3 tw="text-xl font-bold mb-2">{program.name}</h3>
                  <div tw="text-neutral-500">
                    <div>
                      {`${format(
                        utcDate(program.startDate),
                        formats.fullDate
                      )} — ${format(utcDate(program.endDate), formats.fullDate)}`}
                    </div>
                  </div>
                </div>
              </OpaqueCard>
            </Link>
          ))}
        </div>
        <h2 tw="text-xl font-bold mt-2">Up-coming Programs</h2>
      </Contained>
    </>
  );
};

export default Beacons;
