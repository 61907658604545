import { addDays } from "date-fns";

import { Transformer } from "@services/api";

import { AsyncResource } from "./AsyncResource";
import { Order, OrderStatus } from "./Order";
import { Variant } from "./Variant";

type DateString = string;
type DateTimeString = string;

export type OrderVariant = {
  actualShipDate?: DateTimeString;
  addressExternalId?: string;
  addressName: string;
  budgetId?: string;
  cancelationNote: string;
  cancelationType?: string;
  canceledQty: number;
  city: string;
  costCenterName?: string;
  id: string;
  inMarketDate: DateString;
  isRush?: boolean;
  order: AsyncResource<"order"> | Order;
  orderExpeditedDate?: string;
  orderIsExpedited: boolean;
  orderStatus: OrderStatus;
  orderSubmittedAt?: string;
  orderType: string;
  orderUserExternalId?: string;
  orderUserName: string;
  orderWindowInMarketDate: DateString | null;
  poInMarketDate?: DateString;
  price: string;
  program?: string;
  programName?: string;
  promotionDiscount: string;
  qty: number;
  qtyPerPack: number;
  region?: string;
  sku: string;
  state?: string;
  status: string;
  streetAddress1: string;
  streetAddress2?: string;
  territoryName: string;
  totalActualShippingCost: string;
  totalEstimatedShippingCost: string;
  totalEstimatedTax: string;
  totalPrice: string;
  trackingData?: {
    carrier: string;
    number: string;
  };
  variant: Variant;
  zip: string;
};

export const orderVariantDatePropertyMap = {
  "on-demand": "poInMarketDate",
  "pre-order": "orderWindowInMarketDate",
  inventory: "actualShipDate",
}

export const orderVariantTransformer: Transformer = {
  type: ["orderVariant", "orderVariants"],
  deserialize: (attr: OrderVariant) => ({
    ...attr,
    estimatedShipDate: attr.orderSubmittedAt
      ? attr.orderType === "pre-order"
        ? new Date(attr.orderSubmittedAt)
        : attr.orderIsExpedited
        ? addDays(new Date(attr.orderSubmittedAt), 1)
        : addDays(new Date(attr.orderSubmittedAt), 2)
      : null,
  }),
};
