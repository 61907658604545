/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import AddSetupFeeModal from "@components/Purchasing/AddSetupFeeModal";
import PurchaseOrderControl from "@components/Purchasing/PurchaseOrderControl";
import PurchaseOrderDetail from "@components/Purchasing/PurchaseOrderDetail";
import PurchaseOrderOptions from "@components/Purchasing/PurchaseOrderOptions";
import PurchaseOrderVariantOverviewTable from "@components/Purchasing/PurchaseOrderVariantOverviewTable";
import PurchaseOrderVariantTable from "@components/Purchasing/PurchaseOrderVariantTable";
import PurchasingAgentWarehouseAddress from "@components/Purchasing/PurchasingAgentWarehouseAddress";
import ShippingParameterTable from "@components/Purchasing/ShippingParameterTable";
import {
  Contained,
  OpaqueCard,
  StyledButton,
} from "@components/StyledComponents";
import Loading from "@components/Utility/Loading";
import OptionsMenu from "@components/Utility/OptionsMenu";
import OrderPatchLoading from "@components/Utility/OrderPatchLoading";
import { Title, ToggleButtons } from "@features/ui";
import {
  fetchPurchaseOrder,
  setRedirect,
} from "@redux/slices/purchaseOrders/purchaseOrderSlice";
import DocTitle from "@utility/DocTitle";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { useNoFetch } from "../hooks/useNoFetch";

/*
The purchase order view is used for viewing and editing of a single purchase order. Purchase orders
are visible to admin users, purchasers, and suppliers.  Suppliers have limited capabilities when
editing purchase orders, and only have access to a couple fields.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  purchaseOrderWrapper: {
    margin: "0 auto 0 auto",
    width: "100%",
  },
}));

const PurchaseOrder = () => {
  const { type, poId } = useParams();

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const roleIs = useRoleIs();

  const [setupFeeOpen, setSetupFeeOpen] = useState(false);
  const [tableView, setTableView] = useState(
    roleIs("supplier", "select-account-manager") ? "overview" : "itemized"
  );

  const {
    currentPurchaseOrder: purchaseOrder,
    isLoading,
    redirect,
  } = useSelector((state) => state.purchaseOrder);

  const handleSetupFeeOpen = () => setSetupFeeOpen(true);
  const handleSetupFeeClose = () => setSetupFeeOpen(false);

  useEffect(() => {
    if (type === "history" || (type === "new" && !purchaseOrder.id)) {
      dispatch(fetchPurchaseOrder(poId));
    }
  }, [type, poId, dispatch, purchaseOrder.id]);

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
      dispatch(setRedirect({ redirect: false }));
    }
  });

  useNoFetch(dispatch);

  if (
    !purchaseOrder.id ||
    isLoading ||
    (purchaseOrder.id && purchaseOrder.id !== poId)
  ) {
    return <Loading />;
  }

  return (
    <>
      {setSetupFeeOpen && (
        <AddSetupFeeModal
          open={setupFeeOpen}
          handleClose={handleSetupFeeClose}
          purchaseOrderId={purchaseOrder.id}
        />
      )}
      <DocTitle title={`Purchase Order ${poId}`} />
      <Contained>
        <header tw="flex justify-between items-center">
          <Title backUrl={`/purchasing/purchase-order-history`}>
            Purchase Order {poId}
          </Title>

          <OptionsMenu
            skipCloseOnClick
            title="Options Menu"
            component={(props) => (
              <StyledButton
                tw="bg-white"
                outlined
                endIcon={<MoreVertRoundedIcon />}
                {...props}
              >
                OPTIONS
              </StyledButton>
            )}
          >
            <PurchaseOrderOptions />
          </OptionsMenu>
        </header>
        <br />
        <div className={classes.purchaseOrderWrapper}>
          <PurchaseOrderDetail />
          <br />
          <Typography className={classes.titleText}>Itemized Costs</Typography>
          <ToggleButtons
            leftTitle="Overview"
            rightTitle="Itemized"
            onLeftSelect={() => {
              setTableView("overview");
            }}
            onRightSelect={() => {
              setTableView("itemized");
            }}
            selectedValue={tableView === "overview" ? "left" : "right"}
          />
          <OpaqueCard tw="mt-2 p-0">
            {tableView === "itemized" ? (
              <PurchaseOrderVariantTable
                handleSetupFeeOpen={handleSetupFeeOpen}
              />
            ) : (
              <PurchaseOrderVariantOverviewTable setTableView={setTableView} />
            )}
          </OpaqueCard>
          <PurchaseOrderControl />
          <br />
          {purchaseOrder.requiresPurchasingAgent &&
          roleIs(permissions.supplier) &&
          // Suppliers won't be shipping to the warehouse for on-demand purchase-orders
          purchaseOrder.type !== "on-demand" ? (
            <>
              <Typography className={classes.titleText}>
                Warehouse Detail
              </Typography>
              <OpaqueCard tw="mt-2 p-0">
                <PurchasingAgentWarehouseAddress />
              </OpaqueCard>
            </>
          ) : (
            <>
              <Typography className={classes.titleText}>
                Shipping Detail
              </Typography>
              <OpaqueCard tw="mt-2 p-0">
                <ShippingParameterTable />
              </OpaqueCard>
            </>
          )}
          <br />
        </div>
        <br />
        {/* <WithPermission allow={permissions.beaconRanges}>
          <div>
            <Typography className={classes.titleText}>
              Beacon UID Ranges:
            </Typography>
            <BeaconRangesTable tw="mt-2" />
          </div>
        </WithPermission> */}
        <br />
        <br />
        <OrderPatchLoading />
      </Contained>
    </>
  );
};

export default PurchaseOrder;
