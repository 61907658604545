/** @jsxImportSource @emotion/react */
import "twin.macro";

import { ButtonGroup, Chip } from "@mui/material";

import { useBeaconParams, useBeaconProgram } from "../../hooks/beaconHooks";
import { VIEWS } from "../../pages/Beacon";
import { StyledButton } from "../StyledComponents";
import StateSelector from "./StateSelector";
import { isStateCode } from "./helpers";

const BeaconNav = ({ ...props }) => {
  const [{ state, view, programId, variantSku }, setParams] = useBeaconParams();
  const isState = isStateCode(state);
  const { variants } = useBeaconProgram({ programId }, "variants");
  const selectedVariant = variants.find(
    (variant) => variant.variantSku === variantSku
  );
  return (
    <div tw="flex gap-4 justify-between items-center" {...props}>
      <div tw="flex gap-2 items-center">
        <ButtonGroup>
          {isState && (
            <StyledButton
              onClick={() =>
                setParams({ state: "national", view: VIEWS.state })
              }
            >
              National
            </StyledButton>
          )}
          {!isState && (
            <StyledButton
              outlined={view !== VIEWS.state}
              cta={view === VIEWS.state}
              onClick={() => setParams({ view: VIEWS.state })}
            >
              State View
            </StyledButton>
          )}
          <StyledButton
            outlined={view !== VIEWS.item}
            cta={view === VIEWS.item}
            onClick={() => setParams({ view: VIEWS.item })}
          >
            Item View
          </StyledButton>
          {isState && (
            <StyledButton
              outlined={view !== VIEWS.beacon}
              cta={view === VIEWS.beacon}
              onClick={() => setParams({ view: VIEWS.beacon })}
            >
              Beacon View
            </StyledButton>
          )}
        </ButtonGroup>
        {isState && (
          <StateSelector
            value={state}
            onChange={(e) => setParams({ state: e.target.value })}
          />
        )}
        {selectedVariant && (
          <Chip
            label={selectedVariant?.itemName}
            onDelete={() => setParams({ variantSku: "" })}
          />
        )}
      </div>
      <div tw="flex gap-2 items-center"></div>
    </div>
  );
};

export default BeaconNav;
