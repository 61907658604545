const stateCenterZoomMap = {
  AL: { zoom: 7, center: { lat: 32.78, lng: -86.56 } },
  AK: { zoom: 4, center: { lat: 64.68, lng: -153.49 } },
  AS: { zoom: 8, center: { lat: 14.36, lng: -170.11 } },
  AZ: { zoom: 6, center: { lat: 34.3, lng: -111.78 } },
  AR: { zoom: 7, center: { lat: 34.62, lng: -92.29 } },
  CA: { zoom: 6, center: { lat: 37.35, lng: -119.05 } },
  CO: { zoom: 6, center: { lat: 38.97, lng: -105.66 } },
  CT: { zoom: 8, center: { lat: 41.6, lng: -72.68 } },
  DE: { zoom: 9, center: { lat: 39.11, lng: -75.46 } },
  DC: { zoom: 11, center: { lat: 38.9, lng: -77.04 } },
  FM: { zoom: 6, center: { lat: 6.45, lng: 156.39 } },
  FL: { zoom: 6, center: { lat: 28.044, lng: -81.98 } },
  GA: { zoom: 7, center: { lat: 32.814582, lng: -83.295774 } },
  GU: { zoom: 8, center: { lat: 13.455395, lng: 144.777288 } },
  HI: { zoom: 6, center: { lat: 20.419361, lng: -157.446749 } },
  ID: { zoom: 6, center: { lat: 44.416025, lng: -114.431734 } },
  IL: { zoom: 6, center: { lat: 40.050459, lng: -89.183403 } },
  IN: { zoom: 7, center: { lat: 39.742022, lng: -86.058786 } },
  IA: { zoom: 6, center: { lat: 41.893086, lng: -93.570233 } },
  KS: { zoom: 6, center: { lat: 38.287674, lng: -98.289036 } },
  KY: { zoom: 6, center: { lat: 37.574484, lng: -85.012839 } },
  LA: { zoom: 7, center: { lat: 31.052333, lng: -91.748288 } },
  ME: { zoom: 7, center: { lat: 45.14, lng: -69.27 } },
  MH: { zoom: 7, center: { lat: 7.49376, lng: 169.560833 } },
  MD: { zoom: 7, center: { lat: 39.1119739455811, lng: -77.09963378906251 } },
  MA: { zoom: 7, center: { lat: 42.27, lng: -71.97 } },
  MI: { zoom: 6, center: { lat: 44.34, lng: -85.26 } },
  MN: { zoom: 6, center: { lat: 46.13353961792208, lng: -93.9283303339765 } },
  MS: { zoom: 7, center: { lat: 32.67435136785991, lng: -89.69803015039912 } },
  MO: { zoom: 6, center: { lat: 38.70585565539622, lng: -92.44407315435791 } },
  MT: { zoom: 6, center: { lat: 47.39946193086664, lng: -110.61387870109547 } },
  NE: { zoom: 6, center: { lat: 41.534295280288106, lng: -99.90263964428182 } },
  NV: { zoom: 6, center: { lat: 38.9220497637236, lng: -116.64492187500001 } },
  NH: { zoom: 7, center: { lat: 43.86, lng: -71.52 } },
  NJ: { zoom: 8, center: { lat: 40.26, lng: -74.71 } },
  NM: {
    zoom: 6,
    center: { lat: 34.747632084882596, lng: -106.04439325135019 },
  },
  NY: { zoom: 7, center: { lat: 42.81, lng: -75.96 } },
  NC: { zoom: 6, center: { lat: 35.74, lng: -79.86 } },
  ND: {
    zoom: 6,
    center: { lat: 34.747632084882596, lng: -106.04439325135019 },
  },
  MP: { zoom: 7, center: { lat: 16.68999344043122, lng: -214.33363052205038 } },
  OH: { zoom: 7, center: { lat: 40.16, lng: -82.7 } },
  OK: { zoom: 6, center: { lat: 35.44535562751246, lng: -98.54285258411505 } },
  OR: { zoom: 6, center: { lat: 43.81552057272513, lng: -120.538631716211 } },
  PW: { zoom: 9, center: { lat: 7.391348070872819, lng: -225.46402698603998 } },
  PA: { zoom: 7, center: { lat: 40.52, lng: -77.5 } },
  PR: { zoom: 8, center: { lat: 18.233493360367717, lng: -66.4606644291911 } },
  RI: { zoom: 10, center: { lat: 41.71, lng: -71.53 } },
  SC: { zoom: 7, center: { lat: 34.02, lng: -80.77 } },
  SD: { zoom: 6, center: { lat: 44.43593068482485, lng: -100.30061159429096 } },
  TN: { zoom: 6, center: { lat: 35.393583469983525, lng: -86.11404742215426 } },
  TX: { zoom: 5, center: { lat: 31.698187138286567, lng: -99.51878482731982 } },
  UT: {
    zoom: 7,
    center: { lat: 39.502002091187485, lng: -111.34944888584195 },
  },
  VT: { zoom: 7, center: { lat: 44.11, lng: -72.68 } },
  VA: { zoom: 7, center: { lat: 37.08, lng: -78.6 } },
  VI: {
    zoom: 10,
    center: { lat: 17.684619027356625, lng: -64.75799435140955 },
  },
  WA: {
    zoom: 6,
    center: { lat: 47.481448190353476, lng: -120.92926610322351 },
  },
  WV: { zoom: 7, center: { lat: 38.28, lng: -80.38 } },
  WI: { zoom: 6, center: { lat: 44.5, lng: -89.5 } },
  WY: { zoom: 7, center: { lat: 43.13416338333942, lng: -107.67606786710866 } },
  AB: { zoom: 5, center: { lat: 55.00450486446487, lng: -115.86856031071979 } },
  BC: { zoom: 5, center: { lat: 54.8821596486393, lng: -127.77773999821977 } },
  MB: { zoom: 5, center: { lat: 55.19684811703725, lng: -96.43664075664833 } },
  NB: { zoom: 7, center: { lat: 46.71931491812711, lng: -66.44495127352712 } },
  NL: { zoom: 5, center: { lat: 52.870463488403075, lng: -57.2019738069834 } },
  NT: { zoom: 5, center: { lat: 64.80899035235683, lng: -120.32418451822588 } },
  NS: {
    zoom: 6,
    center: { lat: 45.018275896798734, lng: -63.122023121537296 },
  },
  NU: { zoom: 4, center: { lat: 68.4125135039456, lng: -98.96002264592686 } },
  ON: { zoom: 5, center: { lat: 49.70407742520309, lng: -85.36684570312501 } },
  PE: { zoom: 8, center: { lat: 46.44166527884909, lng: -63.22613814079958 } },
  QC: { zoom: 5, center: { lat: 51.503631898828, lng: -73.20521432356009 } },
  SK: { zoom: 5, center: { lat: 55.09639300582575, lng: -106.28039075664833 } },
  YT: { zoom: 5, center: { lat: 64.24403522856893, lng: -134.52484055244545 } },
  national: { zoom: 3, center: { lat: 39.08, lng: -98.1 } },
};

export default stateCenterZoomMap;
