/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { Link, useLocation, useParams } from "react-router-dom";

import { CircularProgress } from "@mui/material";

import { format } from "date-fns";

import { formats } from "@utility/dateTime";

import { useBeaconProgram } from "../../hooks/beaconHooks";
import { OpaqueCard } from "../StyledComponents";

const BeaconList = () => {
  const { programId, state, variantSku } = useParams();
  const { hash } = useLocation();
  const { beacons, isLoading } = useBeaconProgram({
    programId,
    state,
    variantSku,
  });
  return (
    <OpaqueCard tw="p-0 h-full">
      <div tw="p-2 pb-8 overflow-y-auto max-h-full">
        {isLoading && <CircularProgress size={24} tw="p-4" />}
        {beacons.map((beacon, i) => (
          <Link
            key={i}
            to={`#${beacon.id}`}
            css={[
              tw`block px-3 py-2 transition rounded-lg hover:bg-neutral-100 text-neutral-700`,
              hash === `#${beacon.id}` && tw`border-2 border-primary-100`,
            ]}
          >
            <h3 tw="text-base font-bold leading-tight">{beacon.storeName}</h3>
            <div tw="flex flex-col 2xl:(flex-row justify-between items-baseline gap-3)">
              <div tw="text-sm text-neutral-500">
                {`${beacon.storeStreetAddress}, ${beacon.storeCity} ${
                  beacon.storeStateCode
                }, ${beacon.storeZip?.slice(0, 5)}`}
              </div>
              <div tw="text-sm text-primary-600">
                Last pinged{" "}
                {format(new Date(beacon.timestamp), formats.fullDateTime)}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </OpaqueCard>
  );
};

export default BeaconList;
