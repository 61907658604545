import { useNavigate, useParams } from "react-router-dom";

const useSetParams = (paramString) => {
  const params = useParams();
  const navigate = useNavigate();
  return [
    params,
    (obj) => {
      const newParams = { ...params, ...obj };
      const newUrl = paramString
        .replace(/:([\w-_]+)/gi, (_, m1) => newParams[m1] || "")
        .split("/")
        .join("/");
      navigate(newUrl);
    },
  ];
};

export default useSetParams;
