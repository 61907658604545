/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Cancel,
  EditTwoTone,
  HelpTwoTone,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import _ from "lodash";
import PropTypes from "prop-types";

import { setItemPreview } from "@redux/slices/items/itemSlice";
import { formatMoney } from "@utility/utilityFunctions";

import usePreferExternalId from "../../../hooks/usePreferExternalId";
import ImageWrapper from "../../Utility/ImageWrapper";
import { InfoTooltipWide } from "../../Utility/StyledComponents/InfoTooltip";
import CustomizationModal from "../CustomizationModal";
import AddressSelection from "./AddressSelection";

const SetTableHead = ({
  classes,
  measuredRef,
  handleWarning,
  handleDeleteSetVariant,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [tableStyle, setTableStyle] = useState("tableOpen");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [customizationModal, setCustomizationModal] = useState({
    open: false,
    sku: null,
    setVariantIds: null,
    customization: null,
    description: null,
  });

  const preferExternalId = usePreferExternalId();

  const { type, orderSetVariants, orderSetOrders, status } = useSelector(
    (state) => state.currentOrderSet
  );
  const {
    id: userId,
    currentTerritoryId,
    organization: { usesItemCustomization, orderLimitType },
  } = useSelector((state) => state.currentUser);

  const countByItemIds = _(orderSetVariants)
    .groupBy("item.id")
    .mapValues((v) => _.sumBy(v, "count"))
    .value();

  const handleCustomizationModalOpen = (sku, customization, description) => {
    let validIds = orderSetVariants
      .filter((osv) => osv.item.sku === sku)
      .map((v) => v.id);
    setCustomizationModal({
      open: true,
      sku: sku,
      setVariantIds: validIds,
      customization: customization,
      description: description,
    });
  };

  const handleCustomizationModalClose = () =>
    setCustomizationModal({
      open: false,
      sku: null,
      setVariantIds: null,
      customization: null,
      description: null,
    });

  const determineImageUrl = (variant) => {
    return variant.imageUrl ?? variant.item.images.imgUrlThumb.url;
  };

  const determineAvailableToOrder = (variant) => {
    let filteredAllocations = variant.allocations.filter(
      (a) =>
        (a.user && a.user.id === userId) ||
        (a.territory && a.territory.id === currentTerritoryId)
    );

    let allocationExists = filteredAllocations.length === 0 ? false : true;

    let allocationTotal =
      filteredAllocations.length === 0
        ? 0
        : filteredAllocations.reduce((a, b) => a + b.availableQty, 0);

    let total =
      orderLimitType === "allocation-only"
        ? allocationTotal + variant.originalCount
        : orderLimitType === "allocation-plus-global"
        ? variant.onHand + allocationTotal + variant.originalCount
        : allocationExists
        ? allocationTotal + variant.originalCount
        : variant.onHand + variant.originalCount;

    if (total > 0) return total;
  };

  const handleTooltipClose = () => setTooltipOpen(false);

  return (
    <>
      {customizationModal.open && (
        <CustomizationModal
          {...customizationModal}
          handleClose={handleCustomizationModalClose}
        />
      )}
      <TableHead ref={measuredRef} tw="sticky top-0 z-20">
        <TableRow>
          <TableCell
            classes={{ root: classes.colRoot }}
            className={classes.borderRight}
            css={[
              tw`sticky left-0 px-3 py-2 align-top bg-white`,
              orderSetOrders.length === 0 &&
                tw`border-4! rounded-md! border-primary-300! `,
            ]}
          >
            <div className={classes.headerCell}>
              {status === "in-progress" && <AddressSelection />}
            </div>
          </TableCell>
          {orderSetVariants.map((variant, i) => (
            <TableCell
              classes={{ root: classes.root }}
              className={classes.borderRight}
              tw="align-top sticky left-0 bg-white"
              key={`head-cell-${i}`}
            >
              <div className={classes.headerCell}>
                <Tooltip title="Remove from Order">
                  <IconButton
                    onClick={() => {
                      handleWarning(
                        `You are attempting to remove Sku #${variant.sku} from the current order, are you sure you wish to continue?`,
                        {
                          id: variant.id,
                          sku: variant.sku,
                          variantId: variant.variantId,
                          orderSetId: variant.orderSetId,
                        },
                        handleDeleteSetVariant
                      );
                    }}
                    tw="absolute -top-1 -right-1"
                  >
                    <Cancel fontSize="small" />
                  </IconButton>
                </Tooltip>
                <ImageWrapper
                  id={`item-image-${i}`}
                  imgClass={classes.previewImageFloat}
                  alt={variant.item.name}
                  imgUrl={determineImageUrl(variant)}
                  handleClick={() => {
                    dispatch(setItemPreview({ id: variant.item.id }));
                  }}
                />
                <Typography tw="mt-2 font-medium ">
                  {variant.item.name}
                </Typography>
              </div>
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell
            classes={{ root: classes.colRoot }}
            className={classes.borderRight}
            tw="sticky left-0 bg-white border-t border-neutral-200 border-b-0"
          >
            <div className={classes.tableControl}>
              <Typography>Order Details</Typography>
              <IconButton
                aria-label="expand row"
                onClick={() => {
                  setOpen(!open);
                  !open ? setTableStyle(null) : setTableStyle("tableClosed");
                }}
                size="large"
              >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </div>
          </TableCell>
          {orderSetVariants.map((variant, i) => {
            return (
              <TableCell
                classes={{ root: classes.root }}
                className={classes.borderRight}
                tw="border-t border-neutral-200 border-b-0 text-center"
                key={`sku-${i}`}
              >
                <div className={classes.infoCell}>
                  <Typography variant="body2" color="textSecondary">
                    {preferExternalId ? variant.warehouseId : variant.sku}
                  </Typography>
                </div>
              </TableCell>
            );
          })}
        </TableRow>
        {/* HEHEH */}
        <TableRow>
          <TableCell
            classes={{ root: classes.root }}
            colSpan={orderSetVariants.length + 1}
            className={classes[tableStyle]}
            tw="p-0"
          >
            <Collapse in={open} timeout="auto">
              <Box>
                <Table
                  size="small"
                  aria-label="variant-info"
                  tw="border-separate"
                >
                  <TableBody>
                    {usesItemCustomization && type !== "inventory" && (
                      <TableRow tw="bg-neutral-100!">
                        <TableCell
                          classes={{ root: classes.colRoot }}
                          tw="sticky left-0 bg-white"
                          className={classes.borderRight}
                        >
                          <div>
                            <Typography tw="font-medium">
                              Customization
                            </Typography>
                          </div>
                        </TableCell>
                        {orderSetVariants.map((variant, i) => (
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                            key={`customization-${i}`}
                            className={classes.borderRight}
                          >
                            <div className={classes.infoCell}>
                              <Typography className={classes.tableText}>
                                {variant.isCustomizable
                                  ? variant.customization &&
                                    variant.customization.length > 25
                                    ? variant.customization.slice(0, 21) + "..."
                                    : variant.customization ?? "---"
                                  : "Not Customizable"}
                              </Typography>
                              {variant.isCustomizable && (
                                <Tooltip title="Edit item customization">
                                  <IconButton
                                    size="small"
                                    aria-label="edit customization"
                                    style={{
                                      justifySelf: "flex-end",
                                      marginLeft: "5px",
                                    }}
                                    onClick={() =>
                                      handleCustomizationModalOpen(
                                        variant.item.sku,
                                        variant.customization,
                                        variant.customizationDescription
                                      )
                                    }
                                  >
                                    <EditTwoTone
                                      size="small"
                                      color="secondary"
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                    <TableRow tw="bg-neutral-100">
                      <TableCell
                        classes={{ root: classes.colRoot }}
                        tw="sticky left-0 bg-white border-t border-neutral-200"
                        className={classes.borderRight}
                      >
                        <Typography tw="font-medium">Variant</Typography>
                      </TableCell>
                      {orderSetVariants.map((variant, i) => (
                        <TableCell
                          classes={{ root: classes.root }}
                          align="center"
                          key={`variant-names-${i}`}
                          className={classes.borderRight}
                          tw="border-t border-neutral-200"
                        >
                          <div className={classes.infoCell}>
                            {variant.variantNames
                              ? variant.variantNames
                              : "---"}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                    {type === "inventory" && (
                      <TableRow tw="bg-neutral-100">
                        <TableCell
                          classes={{ root: classes.colRoot }}
                          tw="sticky left-0 bg-white"
                          className={classes.borderRight}
                        >
                          <Typography tw="font-medium">
                            On Hand Units
                            <em>{` * as of ${new Date().toLocaleTimeString()}`}</em>
                          </Typography>
                        </TableCell>
                        {orderSetVariants.map((variant, i) => (
                          <TableCell
                            classes={{ root: classes.root }}
                            align="center"
                            key={`variant-names-${i}`}
                            className={classes.borderRight}
                          >
                            <div className={classes.infoCell}>
                              {determineAvailableToOrder(variant)}
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                    <TableRow tw="bg-neutral-100">
                      <TableCell
                        classes={{ root: classes.colRoot }}
                        tw="sticky left-0 bg-white"
                        className={classes.borderRight}
                      >
                        <div style={{ display: "flex" }}>
                          <Typography tw="font-medium">
                            Shipped In Packs Of
                          </Typography>
                          <ClickAwayListener onClickAway={handleTooltipClose}>
                            <InfoTooltipWide
                              style={{ marginLeft: "10px" }}
                              onClose={handleTooltipClose}
                              open={tooltipOpen}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              placement="right-end"
                              title="Please order in individuals units. Brandhub will round up to the nearest pack size."
                            >
                              <IconButton
                                onClick={() => setTooltipOpen(!tooltipOpen)}
                                style={{ padding: 0, marginLeft: "10px" }}
                                size="large"
                              >
                                <HelpTwoTone color="secondary" />
                              </IconButton>
                            </InfoTooltipWide>
                          </ClickAwayListener>
                        </div>
                      </TableCell>
                      {orderSetVariants.map((variant, i) => (
                        <TableCell
                          classes={{ root: classes.root }}
                          align="center"
                          key={`pack-size-${i}`}
                          className={classes.borderRight}
                        >
                          <div className={classes.infoCell}>
                            {variant.item.packSize}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow tw="bg-neutral-100">
                      <TableCell
                        classes={{ root: classes.colRoot }}
                        tw="sticky left-0 bg-white"
                        className={classes.borderRight}
                      >
                        <Typography tw="font-medium">
                          Total Individual Units
                        </Typography>
                      </TableCell>
                      {orderSetVariants.map((variant, i) => (
                        <TableCell
                          classes={{ root: classes.root }}
                          align="center"
                          key={`total-qty-${i}`}
                          className={classes.borderRight}
                        >
                          <div className={classes.infoCell}>
                            {variant.count}&nbsp;
                            {type === "on-demand" && variant.item.moq > 0 && (
                              <span tw="bg-white px-1 py-0.5 rounded text-sm">
                                MOQ:{" "}
                                <span
                                  css={[
                                    tw`font-medium`,
                                    countByItemIds[variant.item.id] >=
                                      variant.item.moq && tw`text-green-700`,
                                  ]}
                                >
                                  {countByItemIds[variant.item.id]}
                                </span>{" "}
                                of {variant.item.moq}
                              </span>
                            )}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow tw="bg-neutral-100">
                      <TableCell
                        classes={{ root: classes.colRoot }}
                        tw="sticky left-0 bg-white"
                        className={classes.borderRight}
                      >
                        <Typography tw="font-medium">
                          Individual Unit Price
                        </Typography>
                      </TableCell>
                      {orderSetVariants.map((variant, i) => (
                        <TableCell
                          key={`price-${i}`}
                          classes={{ root: classes.root }}
                          align="center"
                          className={classes.borderRight}
                        >
                          <div className={classes.infoCell}>
                            {`${formatMoney(variant.price, false)}`}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow tw="bg-neutral-100">
                      <TableCell
                        classes={{ root: classes.colRoot }}
                        tw="sticky left-0 bg-white"
                        className={classes.borderRight}
                      >
                        <Typography tw="font-medium">Total Price</Typography>
                      </TableCell>
                      {orderSetVariants.map((variant, i) => (
                        <TableCell
                          key={`total-price-${i}`}
                          classes={{ root: classes.root }}
                          style={{ textAlign: "center" }}
                          className={classes.borderRight}
                        >
                          <div className={classes.infoCell}>
                            {formatMoney(variant.totalPrice)}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

SetTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  handleWarning: PropTypes.func.isRequired,
  handleDeleteSetVariant: PropTypes.func.isRequired,
};

export default SetTableHead;
