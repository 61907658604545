/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { Fade } from "@mui/material";

import { format } from "date-fns";

import { useApiResource } from "@services/api";
import cld from "@services/cloudinary";
import { formats } from "@utility/dateTime";
import stateCodeMap from "@utility/stateCodeMap";
import { utcDate } from "@utility/utilityFunctions";

import { useBeaconProgram } from "../../hooks/beaconHooks";
import { OpaqueCard } from "../StyledComponents";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import { HeaderSkeleton } from "./Skeleton";
import { isStateCode } from "./helpers";

const FadeWrapper = ({ in: fadeIn, children }) => (
  <Fade in={fadeIn} unmountOnExit mountOnEnter>
    <div>{children}</div>
  </Fade>
);

const ProgramHeader = ({ ...props }) => {
  const { state: focusedState, programId } = useParams();
  const { data: program, error } = useApiResource(`programs`, {
    id: programId,
  });

  const { states } = useBeaconProgram({ programId }, "states");
  const loading = !program && !error;
  const isState = isStateCode(focusedState);

  const progressPercent = useMemo(() => {
    let beaconsPinged = 0;
    let estBeaconsExpected = 0;

    states
      .filter((state) => (isState ? state.stateCode === focusedState : true))
      .forEach((state) => {
        beaconsPinged += state.beaconsPinged;
        estBeaconsExpected += state.estBeaconsExpected;
      });

    if (estBeaconsExpected === 0) return 0;
    return Math.min((beaconsPinged / estBeaconsExpected) * 100, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states, focusedState]);

  return (
    <OpaqueCard {...props}>
      <FadeWrapper in={loading}>
        <HeaderSkeleton />
      </FadeWrapper>
      {program && (
        <FadeWrapper in={!loading}>
          <div tw="flex justify-between">
            <div tw="flex gap-4">
              <img
                tw="w-40 aspect-square rounded-md object-cover flex-none shadow-md"
                src={cld.url(program.cloudinaryId)}
                alt={program.name}
              />
              <div>
                <h2 tw="text-2xl font-bold text-neutral-700 my-2">
                  {program.name}
                </h2>
                <span tw="text-neutral-500">
                  {`${format(
                    utcDate(program.startDate),
                    formats.fullDate
                  )} — ${format(utcDate(program.endDate), formats.fullDate)}`}
                </span>
              </div>
            </div>
            <div tw="flex flex-col items-center justify-center px-6">
              <CircularProgressWithLabel value={progressPercent} />
              <div tw="text-neutral-500 text-sm">
                {stateCodeMap[focusedState] || "National"}
              </div>
            </div>
          </div>
        </FadeWrapper>
      )}
    </OpaqueCard>
  );
};

export default ProgramHeader;
