import React from "react";
import { useSelector } from "react-redux";

import { Button } from "@mui/material";

import clsx from "clsx";
import PropTypes from "prop-types";

import { useCheckoutOptions } from "@features/ordering";

const OrderControl = ({
  classes,
  origin,
  handleWarningOpen,
  handleSubmit,
  setStripeModal,
  determineTotal,
  handleApprove,
  handleOpenDenyModal,
  handleOpenSubmitOnBehalfModal,
}) => {
  const { allowStandardCheckout, allowStripeCheckout, allowBudgetCheckout } =
    useCheckoutOptions();
  const {
    isUpdateLoading,
    id,
    type,
    status,
    errorCells,
    budgetId,
    promotionIds,
  } = useSelector((state) => state.currentOrderSet);
  const { name, role } = useSelector((state) => state.currentUser);

  return (
    <div className={clsx(classes.fullWidth, classes.center)}>
      {origin === "draft" &&
        status !== "submitted" &&
        status !== "approved" && (
          <Button
            className={classes.button}
            color="secondary"
            variant="contained"
            style={{ margin: "0 10px" }}
            onClick={() => handleWarningOpen()}
            disabled={isUpdateLoading || promotionIds.length > 0}
          >
            DELETE ORDER SET
          </Button>
        )}
      {origin === "draft" &&
        ["in-progress", "missed-payment"].includes(status) &&
        (allowStandardCheckout || allowBudgetCheckout) && (
          <Button
            className={classes.button}
            style={{ margin: "0 10px" }}
            color="secondary"
            variant="contained"
            onClick={() => handleSubmit()}
            disabled={
              errorCells.length > 0 ||
              isUpdateLoading ||
              (!allowStandardCheckout && !budgetId)
            }
          >
            SUBMIT
          </Button>
        )}
      {origin === "draft" &&
        ["in-progress", "missed-payment"].includes(status) &&
        ["super", "admin"].includes(role) &&
        (allowStandardCheckout || allowBudgetCheckout) && (
          <Button
            className={classes.button}
            style={{ margin: "0 10px" }}
            color="secondary"
            variant="contained"
            onClick={() => handleOpenSubmitOnBehalfModal()}
            disabled={
              errorCells.length > 0 ||
              isUpdateLoading ||
              (!allowStandardCheckout && !budgetId)
            }
          >
            SUBMIT ON BEHALF
          </Button>
        )}
      {origin === "draft" &&
        ["in-progress", "missed-payment"].includes(status) &&
        allowStripeCheckout && (
          <Button
            className={classes.button}
            style={{ margin: "0 10px" }}
            color="secondary"
            variant="contained"
            onClick={() =>
              setStripeModal({
                open: true,
                id: id,
                userName: name,
                amount: determineTotal(),
                orderType: type,
              })
            }
            disabled={
              errorCells.length > 0 || isUpdateLoading || Boolean(budgetId)
            }
          >
            CREDIT CARD CHECKOUT
          </Button>
        )}
      {origin === "approvals" && !["approved", "canceled"].includes(status) && (
        <>
          <Button
            className={classes.button}
            style={{ margin: "0 10px" }}
            color="secondary"
            variant="contained"
            onClick={() => handleApprove()}
            disabled={errorCells.length > 0 || isUpdateLoading}
          >
            APPROVE
          </Button>
          <Button
            className={classes.button}
            style={{ margin: "0 10px" }}
            color="secondary"
            variant="contained"
            onClick={handleOpenDenyModal}
            disabled={errorCells.length > 0 || isUpdateLoading}
          >
            DENY
          </Button>
        </>
      )}
    </div>
  );
};

OrderControl.propTypes = {
  classes: PropTypes.object.isRequired,
  origin: PropTypes.string,
  handleWarningOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setStripeModal: PropTypes.func.isRequired,
  determineTotal: PropTypes.func.isRequired,
  handleApprove: PropTypes.func.isRequired,
  handleOpenDenyModal: PropTypes.func.isRequired,
  handleOpenSubmitOnBehalfModal: PropTypes.func.isRequired,
};

export default OrderControl;
