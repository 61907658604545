/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";

import stateCenterZoomMap from "@utility/stateCenterZoomMap.js";

import { useBeaconProgram } from "../../hooks/beaconHooks";

const BEACON_FOCUS_ZOOM = 17;

const icons = [
  { color: "red", url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" },
  {
    color: "lightblue",
    url: "http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png",
  },
  {
    color: "green",
    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
  },
  {
    color: "pink",
    url: "http://maps.google.com/mapfiles/ms/icons/pink-dot.png",
  },
  {
    color: "blue",
    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
  },
  {
    color: "orange",
    url: "http://maps.google.com/mapfiles/ms/icons/orange-dot.png",
  },
  {
    color: "purple",
    url: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
  },
  {
    color: "yellow",
    url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
  },
];

// Returns the width and height of our window
// const getWindowSize = () => {
//   const { innerWidth, innerHeight } = window;
//   return { innerWidth, innerHeight };
// };

const buildVariantColorIconDict = (variants) => {
  // Finds all the unique variantSku's and asigns a color to each one
  // Returns a dictionary like so
  // { DEM0000016: {
  //     color: "red",
  //     iconURL: "http://www.google.com/"
  // }}
  let variantColorObj = {};
  for (const variant in variants) {
    const v = variants[variant];
    if (variantColorObj[v.variantSku] === undefined) {
      const icon = icons[Object.keys(variantColorObj).length % icons.length];
      variantColorObj[v.variantSku] = {
        color: icon.color,
        iconURL: icon.url,
      };
    }
  }
  return variantColorObj;
};

const BeaconMap = () => {
  const { programId, state, variantSku } = useParams();
  const [map, setMap] = useState(null);
  const { hash } = useLocation();
  const [activeBeacon, setActiveBeacon] = useState(null);
  // const [windowSize, setWindowSize] = useState(getWindowSize());
  const [variantColorObj, setVariantColorObj] = useState({});
  const navigate = useNavigate();

  // Load variants
  const { variants: bVariants } = useBeaconProgram(
    {
      programId,
      state,
    },
    "variants"
  );

  // Load beacons
  const { beacons } = useBeaconProgram({
    programId,
    state,
    variantSku,
  });

  // const handleIdle = () => {
  //   var bounds = map.getBounds();
  //   var southWest = bounds.getSouthWest();
  //   var northEast = bounds.getNorthEast();

  //   //console.log("SW Map Bounds: " + southWest.lat() + ", " + southWest.lng());
  //   //console.log("NE Map Bounds: " + northEast.lat() + ", " + northEast.lng());

  //   // TODO: Some logic to load new beacons based on bounds
  // };

  // Sets which beacon we've currently clicked on to show it's id
  const handleActiveBeacon = (beacon) => {
    if (beacon.id === activeBeacon) {
      return;
    }
    setActiveBeacon(beacon.id);
    navigate(`#${beacon.id}`);
    map.panTo({ lat: beacon.lat, lng: beacon.lng });
  };

  // Updates our window size.  Helpful for keeping the map width reasonable
  // useEffect(() => {
  //   function handleWindowResize() {
  //     setWindowSize(getWindowSize());
  //   }
  //   window.addEventListener("resize", handleWindowResize);

  //   return () => {
  //     window.removeEventListener("resize", handleWindowResize);
  //   };
  // }, []);

  // Update our variant color dictionary based on beacons
  useEffect(() => {
    if (bVariants?.length) {
      setVariantColorObj(buildVariantColorIconDict(bVariants));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bVariants?.length]);

  // When the state (as in United states state) is changed, re-center and set zoom level.
  useEffect(() => {
    if (map) {
      map.setZoom(stateCenterZoomMap[state].zoom);
      map.panTo(stateCenterZoomMap[state].center);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (hash) {
      const hashBeaconId = hash.substring(1);
      if (hashBeaconId !== activeBeacon) {
        const selectedBeacon = beacons.find(({ id }) => id === hashBeaconId);
        map.setZoom(BEACON_FOCUS_ZOOM);
        map.panTo({
          lat: selectedBeacon.lat,
          lng: selectedBeacon.lng,
        });

        setActiveBeacon(hashBeaconId);
      }
    } else {
      setActiveBeacon(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            borderRadius: "10px",
          }}
          center={stateCenterZoomMap[state].center}
          zoom={stateCenterZoomMap[state].zoom}
          onLoad={(map) => setMap(map)}
          // onIdle={(e) => {
          //   handleIdle();
          // }}
        >
          <MarkerClusterer>
            {(clusterer) =>
              beacons.map((beacon) => (
                <Marker
                  key={beacon.id}
                  position={{
                    title: beacon.id,
                    lat: beacon.lat,
                    lng: beacon.lng,
                  }}
                  clusterer={clusterer}
                  onClick={() => handleActiveBeacon(beacon)}
                  icon={
                    variantColorObj[beacon.variantSku]
                      ? variantColorObj[beacon.variantSku].iconURL
                      : icons[0].url
                  }
                >
                  {activeBeacon === beacon.id ? (
                    <InfoWindow onCloseClick={() => setActiveBeacon(null)}>
                      <div tw="text-center">
                        <div tw="font-semibold">{beacon.storeName}</div>
                        <div>
                          {beacon.storeStreetAddress}, {beacon.storeCity},{" "}
                          {beacon.storeStateCode}
                        </div>
                      </div>
                    </InfoWindow>
                  ) : null}
                </Marker>
              ))
            }
          </MarkerClusterer>
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default BeaconMap;
