import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import _ from "lodash";

import { User } from "@models/User";
import client, { useMutateError } from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

import { UserFormValues } from "../formValuesFromUser";

export const usersKeyFactory = createQueryKeys("users", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () => client.get<User>(`users/${id}`).then((res) => res.data),
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<User[]>("users", {
        params,
      }),
  }),
});

export const useUserQuery = (id: string | null | undefined) =>
  useQuery({
    ...usersKeyFactory.detail(id!),
    enabled: !!id,
  });

export const usePaginatedUsersQuery = buildPaginatedQuery(
  usersKeyFactory.paginated,
  {
    staleTime: 60_1000,
    placeholderData: keepPreviousData,
  }
);

const makeUserPayload = ({
  accountManagerOrganizationIds,
  purchasingAgentOrganizationIds,
  supplierId,
  ...data
}: Omit<UserFormValues, "id">) => ({
  __type: "user",
  ...data,
  ...(data.role === "select-account-manager" && {
    accountManagerOrganizations: accountManagerOrganizationIds.map((id) => ({
      id,
    })),
  }),
  ...(data.role === "purchasing-agent" && {
    purchasingAgentOrganizations: purchasingAgentOrganizationIds.map((id) => ({
      id,
    })),
  }),
  supplier: supplierId && data.role === "supplier" ? { id: supplierId } : null,
  relationshipNames: [
    "accountManagerOrganizations",
    "purchasingAgentOrganizations",
    "channels",
    "groups",
    "territories",
    "supplier",
  ],
});

export const useCreateUserMutation = () => {
  const queryClient = useQueryClient();
  const mutationError = useMutateError();

  return useMutation({
    mutationFn: ({ id: _id, ...data }: UserFormValues) =>
      client.post<User>("users", makeUserPayload(data)).then((res) => res.data),
    onSuccess: (user) => {
      queryClient.invalidateQueries({
        queryKey: usersKeyFactory.paginated._def,
      });
      return queryClient.setQueryData(
        usersKeyFactory.detail(user.id).queryKey,
        user
      );
    },
    onError: (error) => mutationError(error, "User Create"),
  });
};
export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();
  const mutationError = useMutateError();

  return useMutation({
    mutationFn: ({ id, ...data }: UserFormValues) =>
      client
        .update<User>(`users/${id}`, makeUserPayload(data))
        .then((res) => res.data),
    onSuccess: (user) => {
      queryClient.invalidateQueries({
        queryKey: usersKeyFactory.paginated._def,
      });
      return queryClient.setQueryData(
        usersKeyFactory.detail(user.id).queryKey,
        user
      );
    },
    onError: (error) => mutationError(error, "User Update"),
  });
};
