import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { OrderSet } from "@models";
import { setError } from "@redux/slices/errorSlice";
import client, { useApiResource } from "@services/api";

import useDefaultBudgetOrCostCenter from "./useDefaultBudgetOrCostCenter";
import { useDraftOrderSets } from "./useDraftOrderSets";

type PreOrderOptions = {
  orderWindowId?: string;
  programId: string;
};

function useCurrentDraftOrderSet(
  orderSetType: string,
  preOrderOptions?: PreOrderOptions
) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { budgetId, costCenterId } = useDefaultBudgetOrCostCenter({
    warn: orderSetType !== "pre-order" || preOrderOptions?.orderWindowId,
  });
  const { currentTerritoryId, currentChannelId } = useSelector(
    (state: any) => state.currentUser
  );
  const [orderSetCreateLoading, setOrderSetCreateLoading] = useState(false);

  const orderSetClient = useDraftOrderSets();

  const orderSet = orderSetClient.data?.find(
    (orderSet: OrderSet) =>
      orderSet.type === orderSetType &&
      (!currentTerritoryId || orderSet.territory.id === currentTerritoryId) &&
      (!currentChannelId || orderSet.channel?.id === currentChannelId) &&
      (!preOrderOptions ||
        (orderSet.orderWindow?.id === preOrderOptions.orderWindowId &&
          orderSet.program?.id === preOrderOptions.programId))
  );

  const orderSetVariantsClient = useApiResource(
    orderSet && "order-set-variants",
    {
      filter: { orderSetId: orderSet?.id },
    }
  );

  const createOrderSetVariants = async (variantIds: string[]) => {
    try {
      if (!orderSet) {
        // Create orderset
        setOrderSetCreateLoading(true);
        const res = (await client.post(`order-sets`, {
          territoryId: currentTerritoryId,
          channelId: currentChannelId,
          type: orderSetType,
          budgetId: budgetId,
          costCenterId,
          ...preOrderOptions,
        })) as any;
        const newOrderSet = res.data;

        // Start orderset if inactive
        if (newOrderSet.status !== "in-progress") {
          await client
            .post(`order-sets/${newOrderSet.id}/start`, {})
            .catch((e) => {
              console.error(e);
            });
        }
        // We can't use orderSetVariant.create here because it won't be initialized until
        // orderSet.id is set, which is after this function is run.
        await Promise.all(
          variantIds.map((variantId) =>
            client.post("order-set-variants", {
              orderSetId: newOrderSet.id,
              variantId,
            })
          )
        );
        orderSetClient.mutate();
        setOrderSetCreateLoading(false);

        // If the order-set is pre-order, redirect to the order-set page
        if (orderSetType === "pre-order") {
          navigate(`/orders/open/draft/${newOrderSet.id}`);
        }
      } else {
        // Create order set variants
        await orderSetVariantsClient.createMany(
          variantIds.map((variantId) => ({
            orderSetId: orderSet.id,
            variantId,
          }))
        );
      }
    } catch (err: any) {
      orderSetClient.mutate(() => {
        throw err;
      });
      dispatch(setError({ error: err.message, source: "Draft order set" }));
    } finally {
      setOrderSetCreateLoading(false);
    }
  };

  return {
    orderSet,
    orderSetVariants: orderSetVariantsClient.data ?? [],
    createOrderSetVariants,
    ...orderSetClient,
    isLoading:
      orderSetCreateLoading ||
      orderSetClient.isLoading ||
      orderSetVariantsClient.isLoading,
  };
}

export default useCurrentDraftOrderSet;
