/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Button, Typography } from "@mui/material";

import clsx from "clsx";

import { useBudgetQuery, useUserBudgetsPaginated } from "@features/budgets";
import { useCheckoutOptions } from "@features/ordering";
import { updateOrderSetBudget } from "@redux/slices/orders/currentOrderSetSlice";
import { formatMoneyString } from "@utility/utilityFunctions";

import BudgetSelectModal from "./BudgetSelectModal";
import { calculateBudgetTotal } from "./helpers";

const OrderSetBudgets = ({ classes }) => {
  const dispatch = useDispatch();
  const [isBudgetSelectOpen, setBudgetSelectOpen] = useState(false);
  const {
    budgetId,
    totalVariantPrice,
    id: orderId,
    totalEstFreight,
    totalEstTax,
    isUpdateLoading,
    status,
  } = useSelector((state: any) => state.currentOrderSet);
  const {
    organization: { includeShippingInBudgets, includeTaxesInBudgets },
    id: userId,
    currentTerritoryId,
  } = useSelector((state: any) => state.currentUser);

  const { requireBudget, allowStripeCheckout, budgetLocation } =
    useCheckoutOptions();

  const { budgets } = useUserBudgetsPaginated();

  // Load the budget (in SWR) for the selected budgetId on the order set (redux)
  const { data: budget, isFetching: isBudgetLoading } =
    useBudgetQuery(budgetId);

  const handleBudgetSelectClose = () => {
    setBudgetSelectOpen(false);
  };

  const determineSelectedBudgetText = () => {
    if (isUpdateLoading || isBudgetLoading) return "Loading..."; // Redux or swr budgets are loading
    if (!budgetId || budgets?.length === 0) return "No Budget Selected"; // No budgets on the the orderset or available to the user
    if (!budget) return "No Budget Selected"; // Budget on order set not loaded yet or not found when SWR tried to fetch it
    const budgetValue = calculateBudgetTotal(
      budget,
      budgetId,
      includeShippingInBudgets,
      includeTaxesInBudgets,
      totalEstFreight,
      totalEstTax,
      totalVariantPrice
    );
    return `${budget.name} - Available: ${formatMoneyString(budgetValue)}`;
  };

  const budgetIsValid = useMemo(() => {
    if (!budget && !requireBudget) return true;
    if (!budget || !budget.isActive) return false;

    if (budgetLocation === "user") {
      return budget.users.some((user) => user.id === userId);
    } else if (budgetLocation === "territory") {
      return budget.territories.some(
        (territory) => territory.id === currentTerritoryId
      );
    }
  }, [budget, budgetLocation, currentTerritoryId, userId, requireBudget]);

  useEffect(() => {
    // Only check budgets when the order set is in progress
    if (status !== "in-progress") return;
    // Don't do any updates if an update is running
    if (isUpdateLoading || isBudgetLoading) return;
    // Checks if a budget is still valid for a user or territory
    if (budgetIsValid) return;
    // Set to a valid budget or null
    if ((!requireBudget || budgets.length === 0) && budgetId !== null) {
      // The selected budget is no longer valid, unset it
      dispatch(updateOrderSetBudget(orderId, null));
    } else if (requireBudget && budgets.length > 0) {
      const newBudgetId = budgets[0]?.id;
      dispatch(updateOrderSetBudget(orderId, newBudgetId ?? null));
    }
  }, [
    status,
    budgetId,
    budgetIsValid,
    budgetLocation,
    budgets,
    dispatch,
    orderId,
    requireBudget,
    isUpdateLoading,
    isBudgetLoading,
  ]);

  return (
    <>
      {isBudgetSelectOpen && (
        <BudgetSelectModal
          open={isBudgetSelectOpen}
          handleClose={handleBudgetSelectClose}
        />
      )}
      <Typography className={classes.headerText}>Budget:</Typography>
      <Typography className={classes.bodyText}>
        {`${determineSelectedBudgetText()}`}
      </Typography>
      <br />
      {(budgets?.length > 1 ||
        (budgets?.length > 0 && allowStripeCheckout)) && (
        <>
          <div className={clsx(classes.fullWidth, classes.center)}>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => setBudgetSelectOpen(true)}
            >
              UPDATE BUDGET
            </Button>
            {!requireBudget && budgetId && (
              <Button
                className={classes.button}
                style={{ marginLeft: "20px" }}
                variant="contained"
                color="secondary"
                onClick={() => dispatch(updateOrderSetBudget(orderId, null))}
              >
                REMOVE BUDGET
              </Button>
            )}
          </div>
          {allowStripeCheckout && (
            <Typography
              className={classes.bodyText}
              style={{ marginTop: "15px" }}
              color="textSecondary"
            >
              <em>
                If you would like to check out with a credit card, make sure no
                budgets are selected.
              </em>
            </Typography>
          )}
        </>
      )}
      {requireBudget && !budgetId && (
        <Alert severity="error" tw="max-w-lg mx-auto">
          <h3 tw="text-xl">Budget Error</h3>
          <p>
            You no longer have an active budget assigned to you. You won't be
            able to submit this order until you are assigned to a new budget.
          </p>
        </Alert>
      )}
    </>
  );
};

export default OrderSetBudgets;
