/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useParams } from "react-router-dom";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import stateCodeMap from "@utility/stateCodeMap";

import { useBeaconProgram } from "../../hooks/beaconHooks";
import { OpaqueCard } from "../StyledComponents";
import StandardTableHead from "../Utility/StandardTableHead";
import BlueGreenLinearProgress from "./BlueGreenLinearProgress";

let stateHeadCells = [
  { id: "state", label: "State" },
  { id: "beaconsShipped", label: "Beacons Shipped" },
];

const StateList = ({ handleChangeState, scrollRef }) => {
  const { programId, variantSku } = useParams();

  const { states: beaconStates, isLoading } = useBeaconProgram(
    { programId, variantSku },
    "states"
  );

  return (
    <OpaqueCard tw="p-0 h-full">
      <TableContainer ref={scrollRef} tw="max-h-full">
        <Table stickyHeader>
          <StandardTableHead headCells={stateHeadCells} />
          <TableBody tw="text-neutral-700">
            {!isLoading && beaconStates.length === 0 && (
              <TableRow>
                <TableCell colSpan={stateHeadCells.length}>
                  <Typography>
                    There are currently no items that match your search...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              beaconStates.map((row) => {
                const percent = Math.min(
                  (row.beaconsPinged / row.estBeaconsExpected) * 100,
                  100
                );
                return (
                  <TableRow
                    key={row.stateCode}
                    tw="cursor-pointer transition hover:bg-neutral-50"
                    onClick={() => handleChangeState(row.stateCode)}
                  >
                    <TableCell tw="text-base">
                      {stateCodeMap[row.stateCode]}
                    </TableCell>
                    <TableCell>
                      <div tw="text-neutral-500">
                        {`${row.beaconsPinged} / ${row.estBeaconsExpected}`}
                      </div>
                      <BlueGreenLinearProgress
                        variant="determinate"
                        value={percent}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {isLoading && (
              <TableRow>
                <TableCell colSpan={stateHeadCells.length}>
                  <CircularProgress color="primary" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </OpaqueCard>
  );
};

export default StateList;
